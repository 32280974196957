var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-row',{staticClass:"py-4 justify-center max-width",attrs:{"id":"explanation"}},[_c('v-col',[_c('v-card',{staticClass:"main-background",attrs:{"flat":""}},[_c('v-card-title',{staticClass:"justify-center textcolor--text counter-text pb-0"},[_vm._v(" Gesmeerde Bammetjes: ")]),_c('v-card-actions',{staticClass:"justify-center"},[_c('v-img',{staticClass:"flip",class:_vm.$vuetify.breakpoint.lgAndUp
              ? `large-bammetje-size`
              : `medium-bammetje-size`,attrs:{"contain":"","src":require("../assets/Logo.png"),"transition":"scale-transition"}}),_c('v-card-text',{staticClass:"text-center primary--text counter-number",class:_vm.$vuetify.breakpoint.lgAndUp
              ? `large-counter-size`
              : `medium-counter-size`},[_vm._v(" "+_vm._s(0)+" ")]),_c('v-img',{class:_vm.$vuetify.breakpoint.lgAndUp
              ? `large-bammetje-size`
              : `medium-bammetje-size`,attrs:{"contain":"","src":require("../assets/Logo.png"),"transition":"scale-transition"}})],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }