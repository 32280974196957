import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: "#81C266", // Green
        secondary: "#b0bec5",
        tertiary:"#ffffff", //White
        accent: "#8c9eff",
        error: "#b71c1c",
        textcolor: "#636363", //Grey text
        offwhite: "#FAF9F6", //Off-white
      },
    },
  },
});
