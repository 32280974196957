<template>
  <v-row class="py-4 justify-center max-width" id="explanation">
    <v-col>
      <v-card flat class="main-background">
        <v-card-title class="justify-center textcolor--text counter-text pb-0">
          Gesmeerde Bammetjes:
        </v-card-title>

        <v-card-actions class="justify-center">
          <v-img
            contain
            class="flip"
            src="../assets/Logo.png"
            transition="scale-transition"
            :class="
              $vuetify.breakpoint.lgAndUp
                ? `large-bammetje-size`
                : `medium-bammetje-size`
            "
          />
          <v-card-text
            class="text-center primary--text counter-number"
            :class="
              $vuetify.breakpoint.lgAndUp
                ? `large-counter-size`
                : `medium-counter-size`
            "
          >
            {{ 0 }}
          </v-card-text>
          <v-img
            contain
            src="../assets/Logo.png"
            transition="scale-transition"
            :class="
              $vuetify.breakpoint.lgAndUp
                ? `large-bammetje-size`
                : `medium-bammetje-size`
            "
          />
        </v-card-actions>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "FoodCounter",
  methods: {
  },
};
</script>

<style>
.counter-text {
  font-size: 1.8em;
  line-height: 1;
  font-weight: 700;
}

.medium-bammetje-size {
  max-width: 80px;
}

.large-bammetje-size {
  max-width: 180px;
}

.medium-counter-size {
  padding-left: 0px;
  padding-right: 0px;
  font-size: 2.4em;
}

.large-counter-size {
  font-size: 4.8em;
}

.counter-number {
  line-height: 1;
  font-weight: 700;
}

.flip {
  transform: scaleX(-1);
}
</style>
