<template>
  <div>
    <v-footer class="tertiary" elevation="2">
      <v-row justify="center" no-gutters>
        <v-btn
          v-for="(menuItem, index) in menuItems"
          :key="index"
          text
          small
          class="textcolor--text text-none"
          @click="
            menuItem.url
              ? goToSite(menuItem.url)
              : navigateAndScroll(menuItem, $vuetify)
          "
        >
          {{ menuItem.name }}
        </v-btn>
        <v-col class="py-4 text-center textcolor--text background" cols="12">
          {{ new Date().getFullYear() }} — Bammetje
          <a href="https://www.linkedin.com/company/bammetje">
            <v-avatar tile width="24px">
              <img
                class="social-media-icon"
                src="../assets/landing-page-images/icons/social_media_linkedin.svg"
                alt="FavouriteXI LinkedIn"
                title="FavouriteXI LinkedIn"
              />
            </v-avatar>
          </a>
        </v-col>
      </v-row>
    </v-footer>
  </div>
</template>

<script>
import config from '../config.js';

export default {
  name: "PageHeader",
  data() {
    return {
      menuItems: [
        {
          name: "Meld je aan",
          section: null,
          path: "/",
          url: config.appUrl,
        },
        { name: "Over ons", section: null, path: "/about" },
        { name: "FAQ", section: null, path: "/faq" },
        { name: "Contact", section: null, path: "/contact" },
        { name: "Algemene Voorwaarden", section: null, path: "/terms" },
        { name: "Privacybeleid", section: null, path: "/privacy" },
      ],
    };
  },
  methods: {
    goToSite(url) {
      window.location.href = url;
    },
    isHomePage() {
      return this.$route.path == "/";
    },
    navigateAndScroll(menuItem, vuetify) {
      if (this.$route.path !== menuItem.path) {
        this.$router.push(menuItem.path);
        setTimeout(function () {
          if (!menuItem.section) return;
          vuetify.goTo(menuItem.section);
        }, 500);
      } else if (menuItem.section) {
        vuetify.goTo(menuItem.section);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.social-media-icon {
  // filter: grayscale(100%);
  // opacity: 0.7;
  transition: 0.4s;
}

.social-media-icon:hover {
  filter: grayscale(0%);
  opacity: 1;
}

.social-media-icon:active {
  filter: grayscale(0%);
  opacity: 1;
}
</style>
