<template>
  <div class="main-background">
    <HomeCarousel id="top"></HomeCarousel>
    <v-row class="py-4 justify-center max-width" id="belief">
      <v-col class="center">
        <v-card-title color="secondary" class="title-text">
          <v-img max-width="50" class="mr-2" :src="lawRuneIcon"></v-img>
          Dit is waar we voor staan
        </v-card-title>
        <v-card-text class="informative-text">
          Bij Bammetje vinden we dat de ontwikkeling van kinderen altijd
          centraal moet staan. Om ze optimaal te ondersteunen, moeten ze niet
          alleen toegang hebben tot onderwijs, maar ook voldoende te eten
          krijgen. Met een goed gevulde maag kunnen kinderen zich beter
          concentreren en hebben ze minder kans op het oplopen van een
          achterstand.
        </v-card-text>
        <v-card-text class="informative-text">
          Door de ontwikkeling van kinderen voorop te stellen en te investeren
          in gezonde voeding, werken we samen aan een betere toekomst voor
          iedereen.
        </v-card-text>
        <v-row class="justify-center mt-4">
          <v-col cols="12" sm="4">
            <v-row class="pb-0 justify-center">
              <v-img
                max-width="160"
                :src="require(`@/assets/landing-page-images/statistics1.svg`)"
              />
            </v-row>

            <v-row class="mt-0">
              <v-col>
                <v-card-title
                  color="secondary"
                  class="title-text justify-center font-weight-bold"
                >
                  <div class="primary--text text-center">
                    <span class="primary--text">10% van de kinderen<br /></span>
                    <span class="textcolor--text">groeit op in armoede</span>
                  </div>
                </v-card-title>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" sm="4">
            <v-row class="pb-0 justify-center">
              <v-img
                max-width="160"
                :src="require(`@/assets/landing-page-images/statistics2.svg`)"
              />
            </v-row>
            <v-row class="mt-0">
              <v-col>
                <v-card-title
                  color="secondary"
                  class="title-text justify-center font-weight-bold"
                >
                  <div class="primary--text text-center">
                    <span class="primary--text"
                      >25% van het voedsel <br
                    /></span>
                    <span class="textcolor--text"
                      >wordt jaarlijks verspild</span
                    >
                  </div>
                </v-card-title>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <img
      class="max-height-image"
      :src="require(`@/assets/landing-page-images/cutting_bammen.png`)"
    />
    <v-row class="py-4 justify-center max-width" id="approach">
      <v-col class="center">
        <v-card-title color="secondary" class="title-text">
          <v-img max-width="50" class="mr-2" :src="supportIcon"></v-img>
          Dit is hoe het werkt
        </v-card-title>

        <v-card-text class="informative-text">
          Bammetje faciliteert een gebruiksvriendelijke omgeving waar scholen en
          donateurs gemakkelijk met elkaar kunnen communiceren en samenwerken
          aan een gezondere toekomst voor kinderen. Hierdoor ontstaat een sterke
          samenwerking tussen nabijgelegen scholen en donateurs, die bijdraagt
          aan het verschaffen van maaltijden aan kinderen en hen helpt om te
          groeien en te leren.
        </v-card-text>
        <v-card-text class="informative-text">
          Scholen registreren zich op onze website en loggen in op het
          scholenportaal om hun specifieke behoeften kenbaar te maken. Dit omvat
          zaken als dieetwensen en het aantal lunches dat ze elke week nodig
          hebben voor de kinderen op hun school.
        </v-card-text>

        <v-card-text class="informative-text">
          Donateurs registreren zich eveneens op de website om de ingediende
          aanvragen te bekijken en selecteren welke scholen zij van eten/drinken
          kunnen voorzien. Ze worden op de hoogte gebracht wanneer in hun
          directe omgeving nieuwe aanvragen ingediend worden.
        </v-card-text>
      </v-col>
    </v-row>
    <img
      class="max-height-image"
      :src="require(`@/assets/landing-page-images/children_lunchboxes.png`)"
    />

    <v-row class="py-4 justify-center max-width" id="explanation">
      <v-col class="center">
        <v-card-title color="secondary" class="title-text">
          <v-img max-width="50" class="mr-2" :src="phoneIcon"></v-img>
          Dit is de Bammetje app
        </v-card-title>

        <v-card-text class="informative-text">
          In de Bammetje app komen vraag en aanbod anoniem samen. De app maakt
          inzichtelijk hoeveel maaltijden aan de school gedoneerd moeten worden
          voor die dag.
        </v-card-text>
        <v-card-text class="informative-text">
          De app is gebouwd om ervoor te zorgen dat kinderen zich kunnen
          focussen op zaken waar ze wél invloed op hebben: hun schoolwerk én
          plezier maken.
        </v-card-text>

        <v-card-text class="informative-text">
          Kinderen zijn de toekomst, geef onze toekomst een eerlijke kans.
        </v-card-text>

        <v-card-actions class="informative-text pl-4">
          <span class="text-start justify-start"> Begin nu met Bammetje! </span>
        </v-card-actions>
        <v-card-actions class="justify-center">
          <v-btn
            class="primary text-none text--center justfy-center"
            width="180"
            tile
            text
            large
            @click="goToSite(appUrl)"
          >
            Meld je aan
          </v-btn>
        </v-card-actions>
      </v-col>
    </v-row>

    <FoodCounter v-if="this.partners.length > 0"></FoodCounter>
    <img
      v-if="this.partners.length > 0"
      class="max-height-image front"
      :src="require(`@/assets/landing-page-images/happy_kids.png`)"
    />
    <v-row
      class="py-4 justify-center max-width"
      id="partners"
      v-if="this.partners.length > 0"
    >
      <v-col class="center">
        <v-card-title color="secondary" class="title-text">
          <v-img max-width="50" class="mr-2" :src="peopleIcon"></v-img>
          Onze partners
        </v-card-title>
      </v-col>
    </v-row>
    <v-row class="justify-center max-width">
      <v-flex wrap v-for="(partner, index) in partners" :key="index">
        <v-layout justify-center>
          <v-card width="180" flat class="text-center main-background">
            <v-card class="justify-center" flat @click="goToSite(partner.site)">
              <v-img
                class="main-background"
                contain
                :src="require(`../assets/partners/` + partner.image)"
                transition="scale-transition"
              />
            </v-card>
            <v-card-text
              class="font-weight-bold"
              @click="goToSite(partner.site)"
            >
              {{ partner.name }}
            </v-card-text>
          </v-card>
        </v-layout>
      </v-flex>
    </v-row>
  </div>
</template>

<script>
import HomeCarousel from "../components/HomeCarousel";
import FoodCounter from "../components/FoodCounter";
import lawRuneIcon from "@/assets/landing-page-images/icons/law_rune.svg";
import heartIcon from "@/assets/landing-page-images/icons/heart_icon.svg";
import phoneIcon from "@/assets/landing-page-images/icons/phone_icon.svg";
import supportIcon from "@/assets/landing-page-images/icons/support_icon.svg";
import peopleIcon from "@/assets/landing-page-images/icons/people_icon.svg";
import config from "../config.js";

export default {
  name: "HomeView",
  components: { HomeCarousel, FoodCounter },
  data() {
    return {
      appUrl: config.appUrl,
      lawRuneIcon,
      heartIcon,
      phoneIcon,
      supportIcon,
      peopleIcon,
      partners: [
        // {
        //   name: "Niet Graag Een Lege Maag",
        //   image: "ngelm.png",
        //   site: "https://ngelm.nl/",
        // },
        // {
        //   name: "Alliantie Kinderarmoede",
        //   image: "alliantie_kinderarmoede.png",
        //   site: "https://alliantiekinderarmoede.nl/",
        // },
        // {
        //   name: "Compete IT | Solutions",
        //   image: "compete.png",
        //   site: "https://compete.nl/",
        // },
        // {
        //   name: "Eerlijke Start",
        //   image: "s.png",
        //   site: "https://www.stichtingeerlijkestart.nl/",
        // },
        // {
        //   name: "Tommy Tomato",
        //   image: "tommy_tomato.png",
        //   site: "https://www.tommytomato.nl/",
        // },
        // {
        //   name: "Lunchmaatjes",
        //   image: "lunchmaatjes.png",
        //   site: "https://www.lunchmaatjes.nl/",
        // },
        // {
        //   name: "Armoede Fonds",
        //   image: "armoede_fonds.png",
        //   site: "https://www.armoedefonds.nl/",
        // },
        // {
        //   name: "Kinderhulp",
        //   image: "kinder_hulp.png",
        //   site: "https://kinderhulp.nl/",
        // },
        // {
        //   name: "Nationaal Schoolontbijt",
        //   image: "nationaal_school_ontbijt.png",
        //   site: "https://www.schoolontbijt.nl/",
        // },
        // {
        //   name: "Sam&",
        //   image: "samen.png",
        //   site: "https://www.samenvoorallekinderen.nl/",
        // },
        // {
        //   name: "Save the Children",
        //   image: "save_the_children.png",
        //   site: "https://www.savethechildren.net/",
        // },
        // {
        //   name: "VoedselEducatie010",
        //   image: "voedseleducatie_010.png",
        //   site: "https://voedseleducatie010.nl/",
        // },
      ],
    };
  },
  methods: {
    goToSite(url) {
      window.location.href = url;
    },
  },
};
</script>

<style>
.max-width {
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
}

.max-height-image {
  object-fit: cover;
  width: 100%;
  max-height: 360px;
  min-height: 240px;
}
</style>
