import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  getters: {},
  mutations: {},
  actions: {


    sendContactForm(_, { emailaddress, reason, comment, token}) {
      return axios
        .post("https://app.bammetje.app/api/v1/contact/Request", {
          email: emailaddress,
          reason: reason,
          message: comment,
          token: token,
        })
    },
  },

  modules: {},
});
